import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Category from "components/pages/front/Category";
import { graphql } from "gatsby";
import { getCfUrl } from "settings/storageSettings";
import { listArticleByStatusCategory } from "graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  Article,
  ListArticleByStatusCategoryQuery,
  ModelSortDirection,
} from "API";
import { GraphQLResult } from "@aws-amplify/api-graphql";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const {
    articles,
    nextToken,
    category,
    categories,
    footers,
  } = props.pageContext;

  let _articles = articles;
  let myNextToken = nextToken;

  const [myArticle, setMyArticle] = React.useState<Article[]>(_articles);

  async function get() {
    while (myNextToken) {
      const res = (await API.graphql(
        graphqlOperation(listArticleByStatusCategory, {
          filter: { status: { eq: "publish" } },
          sortDirection: ModelSortDirection.DESC,
          categoryID: category.id,
          startDateTime: { le: new Date().toISOString() },
          nextToken: myNextToken,
        })
      )) as GraphQLResult<ListArticleByStatusCategoryQuery>;
      _articles = articles.concat(res.data?.listArticleByStatusCategory?.items);
      myNextToken = res.data?.listArticleByStatusCategory?.nextToken;
    }

    setMyArticle(articles);
  }

  React.useEffect(() => {
    get();
    // eslint-ignore-next-line react-hooks/exhaustive-deps
  }, [myNextToken]);

  return (
    <>
      <GatsbySeo
        title={`${category?.name} 舵オンライン │ 船遊びの情報サイト`}
        description={category?.description}
        openGraph={{
          url: `https://www.kazi-online.com/categories/${category?.slug}`,
          title: `${category?.name} 舵オンライン │ 船遊びの情報サイト`,
          description: category?.description,
          site_name: "舵オンライン │ 船遊びの情報サイト",
          type: "website",
          images: [
            {
              url:
                category?.mainImage && category?.mainImage?.url
                  ? getCfUrl(category?.mainImage.url)
                  : "",
              alt: category?.name,
            },
          ],
        }}
      />
      <Helmet>
        <title>{category?.name} 舵オンライン │ 船遊びの情報サイト</title>
      </Helmet>
      <Layout footers={footers}>
        <Category
          category={category}
          categories={categories}
          articles={myArticle}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query($categoryID: ID) {
    appsync {
      listArticleByStatusCategory(
        categoryID: $categoryID
        filter: { status: { eq: "publish" } }
        sortDirection: DESC
        limit: 300
      ) {
        nextToken
        items {
          mainImage {
            url
            name
          }
          adID
          authorID
          categoryID
          createdAt
          description
          endDateTime
          id
          slug
          startDateTime
          status
          title
          updatedAt
          version
        }
      }
    }
  }
`;
